import PropTypes from 'prop-types';
import get from 'lodash.get';
import React from 'react';
import Helmet from 'react-helmet';
import removeMd from 'remove-markdown';
import { getMetadata } from '../helpers';

import {
  LayoutContextConsumer,
  Overlay,
  Slideshow,
} from '../components';

import {
  getDataCaseStudy,
} from '../proxies';

const CaseStudy = (props = {}) => {
  const {
    pageContext = {},
  } = props;

  return (
    <LayoutContextConsumer>
      <Helmet
        title={get(pageContext, 'title', '')}
        meta={getMetadata({
          metaDescription: {
            metaDescription: removeMd(get(pageContext, 'slideshowCaption.slideshowCaption', '').split(' ').slice(0, 25).join(' ')),
          },
          metaTitle: get(pageContext, 'title', ''),
          metaType: 'Article',
          ogImage: get(pageContext, 'slideshow.0', ''),
        })}
      />
      <Overlay fullScreen>
        <Slideshow {...getDataCaseStudy(pageContext)} />
      </Overlay>
    </LayoutContextConsumer>
  );
};

CaseStudy.propTypes = {
  pageContext: PropTypes.shape({}).isRequired,
};

export default CaseStudy;
